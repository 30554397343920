import React from "react"
import { Link, graphql } from "gatsby"
import GraphQLErrorList from "../components/graph-ql-error-list"
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroText from "../components/modules/heroText"
import FeaturedProjectsGrid from "../components/modules/featuredProjectsGrid"
import HeroProject from "../components/modules/heroProject"
import SplitHero from "../components/modules/splitHero"
import PageRte from "../components/modules/pageRte"
import SixProjectsGrid from "../components/modules/sixProjectsGrid"

export const query = graphql`
  query IndexQuery {
    sanityPages(title: { eq: "Homepage" }) {
      title
      _rawPModules(resolveReferences: { maxDepth: 5 })
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.sanityPages

  const content = (page._rawPModules || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case "heroText":
          el = <HeroText key={c._key} {...c} />
          break
        case "featuredProjectsGrid":
          el = <FeaturedProjectsGrid key={c._key} {...c} />
          break
        case "sixProjectsGrid":
          el = <SixProjectsGrid key={c._key} {...c} />
          break
        case "heroProject":
          el = <HeroProject key={c._key} {...c} />
          break
        case "splitHero":
          el = <SplitHero key={c._key} {...c} />
          break
        case "pageRte":
          el = <PageRte key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  return (
    <Layout>
      <div>{content}</div>
    </Layout>
  )
}

export default IndexPage
